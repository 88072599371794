import React, { useState, useEffect } from "react";
import BreadCrumb from "../component/breadcrumb/Breadcrumb";
import Layout from "../component/Layout";
import GalleryItem from "../component/gallery/GalleryItem";
import FilterData from "../data/filterdata.json";
import GalleryItemVideo from "../component/gallery/GalleryItemVideo";
import { FaSistrix } from "react-icons/fa";
import { API_URL2 } from "../api/APIUrl";

// This Gallery Data will be the posts data we fetch from the database
//filter Data will just be here

const Posts = () => {
  // I would just need to fetch all data and just shove that value into the Gallery Data slot

  const [getAllItems, setItem] = useState([]);
  const [activeFilter, setActiveFilter] = useState("view all");
  const [activeFilter2, setActiveFilter2] = useState("most recent");
  const [visibleItems, setVisibleItems] = useState([]);
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 9;
  const finalIndex = currentPage * itemsPerPage;
  const startingIndex = finalIndex - itemsPerPage;

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    const res = await fetch(`${API_URL2}/api/posts`);

    const res2 = await res.json();
    setVisibleItems(res2);
    setItem(res2);
  };

  const applyFilters = (category, popularity) => {
    let tempData = getAllItems;

    if (category !== "view all") {
      tempData = tempData.filter((data) => data.category === category);
    }

    if (popularity === "popular") {
      tempData = [...tempData].sort(
        (a, b) => a.downloadCount - b.downloadCount
      );
    }
    if (popularity === "most recent") {
      tempData = [...tempData];
    }

    setVisibleItems(tempData);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const newFilter = e.target.textContent.toLowerCase();
    setCurrentPage(1);
    setActiveFilter(newFilter);
    applyFilters(newFilter, activeFilter2);
  };

  const handleChange2 = (e) => {
    e.preventDefault();
    const newFilter2 = e.target.textContent.toLowerCase();
    setCurrentPage(1);
    setActiveFilter2(newFilter2);
    applyFilters(activeFilter, newFilter2);
  };

  //lets filter this via description and make them write detailed desc
  const filteredItems = visibleItems.filter((item) => {
    return item.postDesc.toLowerCase().includes(query.toLowerCase());
  });
  const reversedItems = filteredItems.reverse();
  const items = reversedItems.slice(startingIndex, finalIndex);
  const npage = Math.ceil(reversedItems.length / itemsPerPage);

  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    setCurrentPage(id);
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
  function getPageRange(current, total) {
    const range = [];

    if (total <= 6) {
      for (let i = 1; i <= total; i++) {
        range.push(i);
      }
    } else {
      range.push(1);
      if (current > 4) {
        range.push("...");
      }
      if (current > 3 && current < total - 2) {
        range.push(current - 1, current, current + 1);
      } else if (current <= 3) {
        range.push(2, 3, 4);
      } else {
        range.push(total - 3, total - 2, total - 1);
      }
      if (current < total - 3) {
        range.push("...");
      }
      range.push(total);
    }

    return range;
  }

  return (
    <>
      <Layout>
        <BreadCrumb />
        <div className="gallery-grid-area masonary-wrapper-activation section-gap bg-image overflow-hidden">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-8">
                <div className="button-group isotop-filter filters-button-group2 d-flex justify-content-start ">
                  <button
                    onClick={handleChange2}
                    className={
                      "most recent" === activeFilter2 ? "is-checked" : " "
                    }
                  >
                    Most Recent
                  </button>
                  <button
                    onClick={handleChange2}
                    className={"popular" === activeFilter2 ? "is-checked" : " "}
                  >
                    Popular
                  </button>
                </div>
                <div className="button-group isotop-filter filters-button-group d-flex justify-content-start ">
                  <button
                    onClick={handleChange}
                    className={"view all" === activeFilter ? "is-checked" : " "}
                  >
                    View All
                  </button>
                  <button
                    onClick={handleChange}
                    className={
                      "product post" === activeFilter ? "is-checked" : " "
                    }
                  >
                    Product Post
                  </button>
                  <button
                    onClick={handleChange}
                    className={
                      "product reel" === activeFilter ? "is-checked" : " "
                    }
                  >
                    Product Reel
                  </button>
                  <button
                    onClick={handleChange}
                    className={
                      "educational" === activeFilter ? "is-checked" : " "
                    }
                  >
                    Educational
                  </button>
                  <button
                    onClick={handleChange}
                    className={
                      "company branding" === activeFilter ? "is-checked" : " "
                    }
                  >
                    Company Branding
                  </button>
                </div>
              </div>
              <form
                className="blog-search col-lg-4 col-md-4 col-sm-12"
                action="#"
              >
                <input
                  type="text"
                  placeholder="Search ..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                <button className="search-button">
                  <FaSistrix style={{ color: "black" }} />
                </button>
              </form>
            </div>
            <div className="minec-gallery-items row g-5 mt--5">
              {items.map((item) => {
                if (item.fileExt === "mp4") {
                  return (
                    <div key={item.id} className="col-lg-4 col-md-6 col-12">
                      <GalleryItemVideo key={item.imageId} data={item} />
                    </div>
                  );
                } else {
                  return (
                    <div key={item.id} className="col-lg-4 col-md-6 col-12">
                      <GalleryItem key={item.imageId} data={item} />
                    </div>
                  );
                }
              })}
            </div>
            <ul className="pagination section-gap">
              <li onClick={prePage}>
                <button>Prev</button>
              </li>
              {getPageRange(currentPage, npage).map((n, i) => (
                <li
                  onClick={() =>
                    typeof n === "number" ? changeCPage(n) : null
                  }
                  key={i}
                  className={n === currentPage ? "active" : ""}
                >
                  <button>{n}</button>
                </li>
              ))}
              <li onClick={nextPage}>
                <button>Next</button>
              </li>
            </ul>
          </div>
        </div>
        <br />
      </Layout>
      <br />
      <br />
    </>
  );
};

export default Posts;
