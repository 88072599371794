import React, { useEffect, useState } from "react";
import { API_URL2 } from "../../api/APIUrl";
import Auth from "../../utils/auth";
import { TailSpin } from "react-loader-spinner";

const ZIPForm = () => {
  const [postTitle, setPostTitle] = useState("");
  const [postDesc, setPostDesc] = useState("");
  const [category, setCategory] = useState("");
  const [fileExt, setFileExt] = useState("jpg");
  const [loading, setLoading] = useState(false);
  const [postCountryOfOrigin, setPostCountryOfOrigin] = useState("");
  useEffect(() => {
    setPostCountryOfOrigin(Auth.getCountry());
  }, []);
  const handleformsubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const fileInput = document.querySelector('input[name="uploaded_zip"]')
      .files[0];
    const thumbnailInput = document.querySelector('input[name="postThumb"]')
      .files[0];

    const formdata = new FormData();
    formdata.append("file", fileInput);
    formdata.append("postTitle", postTitle);
    formdata.append("postDesc", postDesc);
    formdata.append("thumbnail", thumbnailInput);
    formdata.append("category", category);
    formdata.append("fileExt", fileExt);
    formdata.append("countryOfOrigin", postCountryOfOrigin);

    if (fileExt === "png") {
      await fetch(`${API_URL2}/api/posts/zip/jpg`, {
        method: "POST",
        body: formdata,
      });
    }
    if (fileExt === "jpg") {
      await fetch(`${API_URL2}/api/posts/zip/jpg`, {
        method: "POST",
        body: formdata,
      });
    }

    event.target.reset();
    setLoading(false);
    alert("Successfully Added Post.");
  };
  return (
    <>
      {loading === false ? (
        <div className="minec-contact-us contact-us-area  bg-color-white">
          <div className="container minec-animated-shape">
            <div className="row g-5 mt--20">
              <div className="col-lg-6">
                <img
                  src="/images/others/upload.webp"
                  style={{
                    borderRadius: "50px",
                    width: "430px",
                    height: "430px",
                  }}
                />
              </div>
              <div className="col-lg-6">
                <form
                  className="rnt-contact-form rwt-dynamic-form row"
                  onSubmit={handleformsubmit}
                >
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="title">Title:</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="title"
                        placeholder="Post Title*"
                        onChange={(e) => setPostTitle(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="description">Description:</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        name="description"
                        placeholder="Post Description* (ie. AnyRidge, packaging, reel, etc)"
                        onChange={(e) => setPostDesc(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label htmlFor="category">Category:</label>
                      <select
                        name="category"
                        id="my-select"
                        required
                        onChange={(e) => {
                          setCategory(e.target.value);
                        }}
                      >
                        <option value="">Please choose a category*</option>
                        <option value="product post">Product Post</option>
                        <option value="product reel">Product Reel</option>
                        <option value="educational">Educational</option>
                        <option value="company branding">
                          Company Branding
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <label htmlFor="uploaded_zip">File:</label>
                    <input
                      type="file"
                      className="form-control-lg"
                      name="uploaded_zip"
                      placeholder="Video*"
                      required
                    />
                  </div>{" "}
                  <div className="col-lg-12">
                    <label htmlFor="postThumb">Thumbnail:</label>
                    <input
                      type="file"
                      className="form-control-lg"
                      name="postThumb"
                      placeholder="Video*"
                      required
                    />
                  </div>
                  <div className="col-lg-12">
                    <button className="rn-btn btn-default w-100" type="submit">
                      <span>Upload</span>
                      <i className="icon-arrow-right-line-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="minec-contact-us contact-us-area bg-color-white">
          <div className="container minec-animated-shape text-center">
            <TailSpin color="#059DFF" />
          </div>
        </div>
      )}
    </>
  );
};

export default ZIPForm;
